import React from "react";
import styled from "styled-components";

import data from "../data/trust.yaml";
import TrustBackground from "../assets/trust/TrustBackground.svg";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/pages/trust/intro";
import Security from "../components/pages/trust/security";
import PrivacyCompliance from "../components/pages/trust/privacyCompliance";
import DataUsageAndOwnership from "../components/pages/trust/dataUsageAndOwnership";

const PurpleBoxOverlay = styled.div`
  position: absolute;
  z-index: -1;
  right: -500px;
  top: -600px;
  width: 1600px;
  height: 1600px;

  background:
      url(${TrustBackground})
      0px 0px / 1500px 1500px
      no-repeat
      border-box;
`;

const TrustPage = () => (
  <Layout
    whiteMobile
    whiteMiddle
    whiteCorner
    headerBackgrounds={[
      <PurpleBoxOverlay key='PurpleBoxOverlay' />
    ]}
  >
    <SEO
      title="Trust"
      keywords={[`percept.ai`, `customer support`, `artifical intelligence`]}
    />

    <Intro data={data} />
    <Security data={data} />
    <PrivacyCompliance data={data} />
    <DataUsageAndOwnership data={data} />
  </Layout>
);

export default TrustPage;
