import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PrivacyComplianceBackground from "../../../assets/trust/PrivacyComplianceBackground.svg";
import Container from '../../container';
import { SectionDescription, SectionHeader } from '../../common';
import { ShadedGlassesIcon } from '../../Icons';
import GDPR from './inline/GDPR.svg';
import { Link } from 'gatsby';
import { baseBoxButton } from '../../button';

const PrivacyComplianceContent = styled(Container)`
  position: relative;

  @media only screen and (max-width: 700px) {
    margin: 200px auto 20px;
    text-align: center;
  }

  @media only screen and (min-width: 700px) {
    margin: 150px auto 300px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 100px;
    align-items: center;
  }
`;

const WhitenedSectionDescription = styled(SectionDescription)`
  font-size: 16px;
  color: #FFFFFF;
  line-height: 30px;
`;

const GDPRSection = styled.div`
  text-align: center;
  height: 100%;
  width: 100%;

  @media only screen and (max-width: 700px) {
    margin: 60px 0;
    transform: scale(1.2);
  }
`;

const GDPRText = styled.div`
  margin-top: -50px;
  font-weight: 600;
  font-size: 64px;
  color: #637BE6;
  letter-spacing: -3.41px;
`;

const PurpleBoxOverlay = styled.div`
  position: absolute;
  z-index: -1;

  @media only screen and (max-width: 700px) {
    left: -225px;
    top: -175px;
    width: 750px;
    height: 750px;
    background:
      url(${PrivacyComplianceBackground})
      0px 0px / 750px 750px
      no-repeat
      border-box;
  }

  @media only screen and (min-width: 700px) {
    left: -300px;
    top: -200px;
    width: 900px;
    height: 900px;
    background:
      url(${PrivacyComplianceBackground})
      0px 0px / 900px 900px
      no-repeat
      border-box;
  }
`;

const LearnMoreButton = styled(Link)`
  ${baseBoxButton}

  background: #95a7ff;
  color: #FFFFFF;
`;

const PrivacyCompliance = ({ data }) => {
  const { title, description } = data.privacyCompliance;
  return (
    <section id="privacyCompliance">
      <PrivacyComplianceContent>
        <WhitenedSectionDescription>
          <ShadedGlassesIcon />
          <SectionHeader>{title}</SectionHeader>
          <SectionDescription whiten>{description}</SectionDescription>
          <LearnMoreButton to='/gdpr'>Learn more</LearnMoreButton>
        </WhitenedSectionDescription>
        <GDPRSection>
          <GDPR />
          <GDPRText>GDPR</GDPRText>
        </GDPRSection>
        <PurpleBoxOverlay />
      </PrivacyComplianceContent>
    </section>
  );
};

PrivacyCompliance.propTypes = {
  data: PropTypes.object,
};

export default PrivacyCompliance;
