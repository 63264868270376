import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../../container';
import { HeartIcon, ChatBubbleIcon } from '../../Icons';
import { ImageGrid, AssetImage } from '../../common';

import facebookLogo from "../../../assets/logos/facebook.png";
import googleLogo from "../../../assets/logos/google.png";
import amazonLogo from "../../../assets/logos/amazon.png";
import Card from './card';
import { CenteredMainTitle, CenteredMainDescription } from '../../intro/common';
import { Link } from 'gatsby';
import { baseBoxButton } from '../../button';

const IntroContent = styled(Container)`
  margin-top: 50px;
`;

const IntroCardHolder = styled.div`
  margin: 30px 0 0 0;

  @media only screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
  }
`;

const ScheduleDemoButton = styled(Link)`
  ${baseBoxButton}

  background: #637BE610;
  color: #637BE6;
`;

const Intro = ({ data }) => {
  const { title, description, qualityAndConsistency, keepsLearning, bestInClass } = data.intro;

  return (
    <section id="intro">
      <IntroContent>
        <CenteredMainTitle whiten>
          {title}
        </CenteredMainTitle>
        <CenteredMainDescription whiten>
          {description}
        </CenteredMainDescription>
        <IntroCardHolder>
          <Card
            icon={<HeartIcon />}
            title={qualityAndConsistency.title}
            description={qualityAndConsistency.description}
          />
          <Card
            icon={<ChatBubbleIcon />}
            title={keepsLearning.title}
            description={keepsLearning.description}
          />
          <Card
            icon={
              <ImageGrid>
                <AssetImage src={facebookLogo} alt="facebook" />
                <AssetImage src={googleLogo} alt="google" />
                <AssetImage src={amazonLogo} alt="amazon" />
              </ImageGrid>
            }
            title={bestInClass.title}
            description={bestInClass.description}
          />
        </IntroCardHolder>
      </IntroContent>
    </section>
  );
};

Intro.propTypes = {
  data: PropTypes.object,
};

export default Intro;
