import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../../container';
import { ShadedShieldIcon, DataKeyIcon, LockIcon, CloudShieldIcon } from '../../Icons';
import Card from './card';

const SecurityContent = styled(Container)`
  @media only screen and (max-width: 700px) {
    margin: 100px 0 20px;
    text-align: center;
  }

  @media only screen and (min-width: 700px) {
    margin-top: 150px;
  }
`;

const SecurityCardHolder = styled.div`
  @media only screen and (max-width: 700px) {
    text-align: center;
  }

  @media only screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
  }
`;

const Security = ({ data }) => {
  const { title, dataEncryption, applicationSecurity, physicalSecurity } = data.security;
  return (
    <section id="security">
      <SecurityContent>
        <ShadedShieldIcon />
        <h2>{title}</h2>
        <SecurityCardHolder>
          <Card
            icon={<DataKeyIcon />}
            title={dataEncryption.title}
            description={dataEncryption.description}
            shift
            transparent
          />
          <Card
            icon={<LockIcon />}
            title={applicationSecurity.title}
            description={applicationSecurity.description}
            shift
            transparent
          />
          <Card
            icon={<CloudShieldIcon />}
            title={physicalSecurity.title}
            description={physicalSecurity.description}
            shift
            transparent
          />
        </SecurityCardHolder>
      </SecurityContent>
    </section>
  );
};

Security.propTypes = {
  data: PropTypes.object,
};

export default Security;
