import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card from './card';
import { DataOwnershipIcon, ShadedProfileIcon, SecureUserIcon } from '../../Icons';
import { SectionHeader, SectionDescription } from '../../common';
import Container from '../../container';
import { Link } from 'gatsby';
import { baseBoxButton } from '../../button';

const DataUsageAndOwnershipContent = styled(Container)`
  @media only screen and (max-width: 700px) {
    margin: 100px 0 20px;
    text-align: center;
  }

  @media only screen and (min-width: 700px) {
    margin-top: 150px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }
`;

const Textual = styled.div`
  @media only screen and (min-width: 700px) {
    padding: 0 10px 0 0;
  }
`;

const ScheduleDemoButton = styled(Link)`
  ${baseBoxButton}

  background: #637BE610;
  color: #637BE6;
`;

const DataUsageAndOwnership = ({ data }) => {
  const { title, description, sections } = data.dataUsageAndOwnership;
  return (
    <section id="dataUsageAndOwnership">
      <DataUsageAndOwnershipContent>
        <Textual>
          <ShadedProfileIcon />
          <SectionHeader>{title}</SectionHeader>
          <SectionDescription>{description}</SectionDescription>
        </Textual>
        <Card
          icon={<SecureUserIcon />}
          title={sections.dataUsage.title}
          description={sections.dataUsage.description}
        />
        <Card
          icon={<DataOwnershipIcon />}
          title={sections.dataOwnership.title}
          description={sections.dataOwnership.description}
        />
      </DataUsageAndOwnershipContent>
    </section>
  );
};

DataUsageAndOwnership.propTypes = {
  data: PropTypes.object,
};

export default DataUsageAndOwnership;
